<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.sanction_type_items") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
              <!-- <div class="block-sarche ml-2">
                              <select-branch  
                                :size="'small'"
                                :class="mode ? 'input__day' : 'input__night'"
                                :id="filterForm.branch_id"
                                v-model="filterForm.branch_id" >
                              </select-branch>
                            </div> -->
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'sanction_type_element.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          :class="mode ? 'table__myday' : 'table__mynight'"
          class="table-my-code table-bordered"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.position.show">
                {{ columns.position.title }}
              </th>
              <th v-if="columns.fix.show">
                {{ columns.fix.title }}
              </th>
              <th v-if="columns.create_sanction_late.show">
                {{ columns.create_sanction_late.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.position.show">
                <select-position
                  v-model="filterForm.position_id"
                  :id="filterForm.position_id"
                  :size="'mini'"
                ></select-position>
              </th>
              <th v-if="columns.fix.show">
                <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                  :inputValue="filterForm.fix"
                  :placeholder="columns.fix.title"
                  v-model="filterForm.fix"
                  size="mini"
                  max="100"
                  :type="'number'"
                ></crm-input>
              </th>
              <th v-if="columns.create_sanction_late.show">
                <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                  :inputValue="filterForm.create_sanction_late"
                  :placeholder="$t('message.create_sanction_late')"
                  v-model="filterForm.create_sanction_late"
                  size="mini"
                  max="100"
                  :type="'number'"
                ></crm-input>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="sanctionTypeItem in list"
              :key="sanctionTypeItem.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ sanctionTypeItem.id }}</td>
              <td v-if="columns.position.show">
                {{
                  sanctionTypeItem.position
                    ? sanctionTypeItem.position.name
                    : ""
                }}
              </td>
              <td v-if="columns.fix.show">
                {{ sanctionTypeItem.fix }}
              </td>
              <td v-if="columns.create_sanction_late.show">
                {{ sanctionTypeItem.create_sanction_late }}
              </td>
              <td v-if="columns.created_at.show">
                {{ sanctionTypeItem.created_at }}
              </td>
              <td v-if="columns.updated_at.show">
                {{ sanctionTypeItem.updated_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="sanctionTypeItem"
                  :actions="actions"
                  @edit="edit"
                  :permissionShow="'sanction_type_element.edit'"
                  :permissionDestroy="'anction_type_element.destroy'"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "sanctionTypeItemController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate
  },

  data() {
    return {
      filterForm: {
        sanction_type_id: this.$route.params.id
      }
    };
  },
  created() {
    this.filterForm.sanction_type_id = this.$route.params.id;
  },
  computed: {
    ...mapGetters({
      list: "sanctionTypeItem/list",
      columns: "sanctionTypeItem/columns",
      pagination: "sanctionTypeItem/pagination",
      statues: "sanctionTypeItem/statues",
      filter: "sanctionTypeItem/filter",
      sort: "sanctionTypeItem/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "sanctionTypeItem/index",
      setPagination: "sanctionTypeItem/setPagination",
      updateSort: "sanctionTypeItem/updateSort",
      updateFilter: "sanctionTypeItem/updateFilter",
      updateColumn: "sanctionTypeItem/updateColumn",
      updatePagination: "sanctionTypeItem/updatePagination",
      show: "sanctionTypeItem/show",
      empty: "sanctionTypeItem/empty",
      delete: "sanctionTypeItem/destroy",
      refreshData: "sanctionTypeItem/refreshData"
    })
  }
};
</script>
